import React from 'react';
import {useHistory} from 'react-router-dom';
import {translate as _} from '@morawadigital/skynet-framework';
import {Dropdown} from 'react-bootstrap';
import Item from './Item';

function Event(props) {
    const history = useHistory();
    const {item, index} = props;
    const url = item && `${_('/event/')}${item.Id}`;

    const renderOptions = (url) => {
        return (
            <Dropdown.Item as='button' onClick={() => history.push(url)}>
                {_('Bearbeiten')}
            </Dropdown.Item>
        );
    };


    return (
        <Item
            index={index}
            item={item}
            name={item && item.Name}
            options={renderOptions(url)}
            url={url}
        />
    );
}

export default Event;
