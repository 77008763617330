import {translate as _} from '@morawadigital/skynet-framework'
import {FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_COLOR, FIELD_TYPE_DATE, FIELD_TYPE_TEXTFIELD} from '../../../util/constants'

const fields = [
  {
    object: 'Event',
    property: 'Name',
    label: _('Name'),
    __type: FIELD_TYPE_TEXTFIELD
  },
  {
    object: 'Event',
    property: 'StartDate',
    label: _('Start Date'),
    __type: FIELD_TYPE_DATE
  },
  {
    object: 'Event',
    property: 'EndDate',
    label: _('End Date'),
    __type: FIELD_TYPE_DATE
  },
  {
    object: 'Event',
    property: 'DailyStart',
    label: _('Daily Start'),
    __type: FIELD_TYPE_DATE
  },
  {
    object: 'Event',
    property: 'DailyEnd',
    label: _('Daily End'),
    __type: FIELD_TYPE_DATE
  }
]


export const getFields = () => fields