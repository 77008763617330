import React, {useState, useEffect} from 'react';
import {Card, Form} from 'react-bootstrap';
import {Prompt, withRouter} from 'react-router-dom';
import {Icon, ajx, translate as _} from '@morawadigital/skynet-framework';
import {getDateValue, getStringValue, isNum} from '../../../util';
import FieldSet from '../../elements/FieldSet';
import useEventResults from "../../../hooks/event/use-event-results";
import useUnsavedChanges from "../../../hooks/use-unsaved-changes";
import {useHistory} from 'react-router-dom';
import Floaties from "../../controls/Floaties";

function NewOrEdit(props) {
    const {match} = props
    const [saving, setSaving] = useState(false)
    const [validated, setValidated] = useState(false)
    const history = useHistory();

    const [fieldDefinition, valueOptions, event, setEvent] = useEventResults({
        id: match.params.id,
        getId: isNum(match.params.id)
    })

    const getEventId = event.Id || match.params.id || 0;

    const [hasUnsavedChanges, setHasUnsavedChanges] = useUnsavedChanges();

    const save = (e) => {
        e.preventDefault()

        if (!e.currentTarget.checkValidity()) {
            setValidated(true)
            return;
        }

        setSaving(true)
        setValidated(true)

        const data = {
            id: event.Id,
            name: getStringValue(event.Name),
            startDate: getDateValue(event.StartDate),
            endDate: getDateValue(event.EndDate),
        }

        const success = (e) => {
            setHasUnsavedChanges(false);

            if (!event.Id) {
                setEvent({...event, Id: e.Id})
            }


            setEvent((prevEvent) => ({
                ...prevEvent,
                Id: isNum(getEventId) ? prevEvent.Id : e.Id
            }))

        }

        ajx({
            complete: () => setSaving(false),
            single: true,
            url: 'api/Event/AddOrEdit',
            data,
            success,
        })
    }

    const onChangeFieldHandler = (e, f) => {
        setEvent((prevComp) => ({
            ...prevComp,
            [e]: f,
        }))
        setHasUnsavedChanges(f !== null)
    }

    const formDisabled = props.loading || saving;

    useEffect(() => {
        if (event.Id !== 0 && isNum(match.params.id) && !hasUnsavedChanges) {
            history.push(_('/event/') + event.Id)
        }
    }, [event.Id])

    return (
        <>
            <div className="subheader">
                <h1 className="subheader-title">
                    <Icon icon="trophy" className="subheader-icon"/>{' '}
                    {_('Event')}{' '}
                    {event && (
                        <span className="fw-300">
                            {event?.Name || ''}
                        </span>
                    )}
                </h1>
            </div>
            <Card>
                <fieldset disabled={formDisabled}>
                    <Card.Body>
                        <Form
                            onSubmit={(e) => save(e)}
                            noValidate
                            validated={validated}
                        >
                            <FieldSet
                                disabled={formDisabled}
                                fieldDefinition={fieldDefinition}
                                onChange={onChangeFieldHandler}
                                valueOptions={valueOptions}
                                values={event}
                                isNew={!isNum(getEventId)}
                            />
                            <Floaties
                                buttons={[{
                                    icon: 'save',
                                    label: _(
                                        'Speichern'
                                    ),
                                    disabled: formDisabled,
                                    type: 'submit',
                                }]}
                            />
                        </Form>

                    </Card.Body>
                </fieldset>
            </Card>
            <Prompt
                message={_(
                    'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.'
                )}
                when={hasUnsavedChanges}
            />
        </>
    )
}

export default withRouter(NewOrEdit)
