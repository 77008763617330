import React from 'react'
import { ErrorBoundary, Icon, Login, MenuItem, Skynet, createDate, reloadPage } from '@morawadigital/skynet-framework'
import { Route } from 'react-router-dom'
import packageJson from '../../../package.json'
import Dashboard from './pages/Dashboard'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import Import from './pages/Import'
import Events from "./../containers/pages/Events";
import NewOrEdit from "./../containers/pages/Events/NewOrEdit";

class App extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            appUpdateAvailable: false,
            updatingApp:        false,

        }

    }

    componentDidMount() {

        setTimeout( () => {

            if ( window.appUpdateAvailable ) {

                this.setState( { appUpdateAvailable: true } )

                setTimeout( () => this.setState( { appUpdateAvailable: false } ), 1000 * 60 )

            }

        }, 1000 * 5 )

    }

    hideAppUpdateNotification() {

        this.setState( { appUpdateAvailable: false } )

    }

    updateApp() {

        this.setState( { updatingApp: true }, () => {

            caches
                .keys()
                .then( keys => keys.forEach( c => caches.delete( c ) ) )
                .then( () => setTimeout( reloadPage, 1000 * 3 ) )

        } )

    }

    render() {

        const logoImageUrl            = '/assets/logo.png'
        const logoText                = 'Metrics'
        const mainNavigationMenuItems = []
        const pageRoutes              = []

        // TODO Events page

        mainNavigationMenuItems.push( <MenuItem url='/' key='dashboard'><><Icon fw icon='dashboard' /><span className='nav-link-text'>Dashboard</span></></MenuItem> )
        mainNavigationMenuItems.push( <MenuItem url='/events' key='events'><><Icon fw icon='calendar-days' /><span className='nav-link-text'>Events</span></></MenuItem> )
        mainNavigationMenuItems.push( <MenuItem url='/reporting' key='reporting'><><Icon fw icon='chart-line' /><span className='nav-link-text'>Reporting</span></></MenuItem> )
        mainNavigationMenuItems.push( <MenuItem url='/import' key='import'><><Icon fw icon='file-import' /><span className='nav-link-text'>Import</span></></MenuItem> )

        pageRoutes.push( <Route path='/event/:id' key='events' children={ <NewOrEdit /> } /> )
        pageRoutes.push( <Route path='/events' key='events' children={ <Events /> } /> )
        pageRoutes.push( <Route path='/import' key='import' children={ <Import /> } /> )
        pageRoutes.push( <Route path='/' key='dashboard' children={ <Dashboard /> } /> )

        const conf = {

            footerLeft:    createDate().getFullYear() + ' © Morawa Digital',
            footerRight:   'AFBÖ Metrics v' + packageJson.version,
            rootComponent: <Login logoImageUrl={ logoImageUrl } logoText={ logoText } />,

            logoImageUrl,
            logoText,
            mainNavigationMenuItems,
            pageRoutes,

        }

        return (

            <ErrorBoundary>

                <Skynet { ...conf } />

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast show={ this.state.appUpdateAvailable } onClose={ () => this.hideAppUpdateNotification() }>

                        <Toast.Header>

                            <div className='flex-grow-1'>Update verfügbar</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>Eine neue App-Version ist verfügbar!</p>

                            <Button onClick={ () => this.updateApp() } disabled={ this.state.updatingApp }>{ this.state.updatingApp ? 'Installiere...' : 'Jetzt installieren' }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </ErrorBoundary>

        )

    }

}

export default App